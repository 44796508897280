<template>
  <div id="organisation-data-upload">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <b-container>
      <h1 class="mb-3">Add Attachments for {{ organisation.name }}</h1>

      <div v-if="busyLoadingOrg">
        <Busy primary />
      </div>
      <FileUpload
        v-else
        ref="fileUpload"
        rename
        accept="image/png, image/jpeg, application/pdf"
        :busy-uploading="busyUploading"
        @upload-files="uploadFiles"
      />
    </b-container>
  </div>
</template>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import Busy from "@/components/Busy";
import FileUpload from "@/components/FileUpload";

export default {
  name: "OrganisationDataUpload",
  components: { Busy, FileUpload },
  data() {
    return {
      title: "Add Attachments",
      organisation: {},
      busyUploading: false,
      busyLoadingOrg: false
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.busyLoadingOrg = true;
      const organisationId = this.$route.params.organisationId;

      try {
        const client = await ApiHelper.http();
        const response = await client.get(`${ApiHelper.endPoint()}organisations/${organisationId}`);

        if (response.data.status === "success") {
          this.organisation = response.data.organisation;
        } else {
          this.showMessage("There was a problem getting the organisation.", "warning");
        }
      } catch {
        this.showMessage("There was a problem getting the organisation.", "warning");
      } finally {
        this.busyLoadingOrg = false;
      }
    },

    async uploadFiles(formData) {
      this.busyUploading = true;
      formData.append("organisationId", this.$route.params.organisationId);
      try {
        const client = await ApiHelper.http();
        const response = await client.post(`${ApiHelper.endPoint()}organisations/files`, formData);

        if (response.data.status === "success") {
          this.$refs.fileUpload.clearSelected();
          this.showMessage("Organisation file(s) saved successfully.", "success");
        } else {
          this.showMessage(
            "There was a problem saving the organisation file(s): " + response.data.message,
            "warning"
          );
        }
      } catch (error) {
        this.showMessage(
          "There was a problem saving the organisation file(s): " + error.response.data.message,
          "warning"
        );
      } finally {
        this.busyUploading = false;
      }
    },

    showMessage(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    }
  }
};
</script>
